.dashboard-discovery-map {
  width: 100%;
  height: calc(100vh - 64px);
  z-index: 1;
}
.locationChangeBtn {
  position: absolute;
  z-index: 10;
  left: 24px;
  top: 24px;
  font-size: 0.75em;
}
.recenterBtn {
  position: absolute;
  z-index: 10;
  right: 24px;
  bottom: 24px;
}
.zoomInBtn {
  position: absolute;
  z-index: 10;
  left: 24px;
  bottom: 96px;
}
.zoomOutBtn {
  position: absolute;
  z-index: 10;
  left: 24px;
  bottom: 24px;
}
@media only screen and (max-width: 936px) {
  .recenterBtn {
    bottom: 324px;
  }
}
/*# sourceMappingURL=src/pages/dashboard/map-discovery/index.css.map */